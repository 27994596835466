import { useState, useEffect, useCallback } from "react"
import { useOutletContext } from "react-router-dom"

import { ROLES } from "@components/Domain"
import { useAppContext } from "@components/AppContext"
import { createMemberOperation } from "@api/services/backstage"

import getInputs from "./getInputs"
import getHiddenAdditionalPermissionInputNames from "./getHiddenAdditionalPermissionInputNames"


const useSchema = (form) => {
  const [ schema, setSchema ] = useState()

  const { userGroups } = useOutletContext()

  const { isConsoleOrganization, getOrganizationName } = useAppContext()

  const organizationName = getOrganizationName()

  const updateSchema = useCallback(() => {
    const { roles = [] } = form.getFieldsValue()

    const HIDE_INPUTS = getHiddenAdditionalPermissionInputNames(roles)

    const isManager = roles.includes(ROLES.MANAGER)

    const createInputs = getInputs({
      isManager,
      userGroups,
      updateSchema,
      organizationName,
      isConsoleOrganization
    })
      .filter(input => !HIDE_INPUTS.includes(input.name))

    const createSchema = createMemberOperation.getSchema(createInputs)

    setSchema(createSchema)
  }, [
    form,
    organizationName,
    isConsoleOrganization,
    userGroups
  ])

  useEffect(() => {
    updateSchema()
  }, [ updateSchema ])

  return schema
}

export default useSchema
export { createMemberOperation }
