import React, { useMemo } from "react"
import PropTypes from "prop-types"

import { UpdateModal } from "@components/Page"
import { MODAL_WIDTH2 } from "@components"
import { getIsoDateFromStringDate } from "@components/Date"
import { useForm, useWatch, TYPE_DATE, TYPE_NONE } from "@components/Form"
import { updateInvestmentAccreditationOperation as operation } from "@api/services/investments"

const LABEL_SAVE = "Save"
const LABEL_ENTITY = "Accreditation"
const LABEL_CHANGE_EMAIL = "Edit Investment Accreditation"


const InvestmentAccreditationUpdateModal = ({
  modal,
  onSuccess,
}) => {
  const form = useForm()
  const hasAccreditation = useWatch("hasAccreditation", form)

  const schema = useMemo(() =>
    operation.getSchema([
      {
        name: "hasAccreditation",
        label: "Is an accredited investor?",
        required: false,
        inputProps: {
          onChange: ({ target: { value } }) => {
            if (!value) {
              form.setFieldValue(["accreditationExpiresAt"], undefined)
            }
          }
        }
      },
      {
        name: "accreditationExpiresAt",
        label: "Accreditation Expiration Date",
        required: false,
        type: hasAccreditation
          ? TYPE_DATE
          : TYPE_NONE,
      },
    ])
  , [ form, hasAccreditation ])

  const onSubmit = parameters => {
    const { mutation } = parameters
    const { accreditationExpiresAt } = mutation

    if (accreditationExpiresAt) {
      const isoDate = getIsoDateFromStringDate(accreditationExpiresAt)
      parameters.mutation.accreditationExpiresAt = `${isoDate}T00:00:00Z`
      parameters.mutation.hasAccreditation = true
    }

    return parameters
  }

  const updateModalProps = {
    width: MODAL_WIDTH2,
    title: LABEL_CHANGE_EMAIL,
    entity: LABEL_ENTITY,
    submitLabel: LABEL_SAVE,
    shouldOptimizeUpdateMutation: false,
    form,
    modal,
    schema,
    onSubmit,
    onSuccess,
    operation,
  }

  return (
    <UpdateModal
      {...updateModalProps}
    />
  )
}

InvestmentAccreditationUpdateModal.propTypes = {
  modal: PropTypes.shape().isRequired,
  onSuccess: PropTypes.func.isRequired,
}

export default InvestmentAccreditationUpdateModal
