import React, { useRef } from "react"
import PropTypes from "prop-types"
import { Descriptions } from "antd"

import { InvestorDrawer } from "@components/Investor"
import { ReadAccountOutputShape } from "@api/services/investments"

import getItems from "./helpers/getItems"
import { InvestmentShape } from "../../../shapes"

import "./InvestmentDescriptions.css"

const { Item } = Descriptions


const InvestmentDescriptions = ({
  account,
  investment,
  readInvestment,
  openInvestmentProfileUpdateDrawer,
  openInvestmentAccreditationUpdateModal,
}) => {
  const accountDrawer = useRef({})

  const { id, investorAccountId } = investment

  const openAccountDrawer = () =>
    accountDrawer.current.open(investorAccountId)

  const onAfterAccountUpdate = () =>
    readInvestment(id)

  const items = getItems({
    account,
    investment,
    openAccountDrawer,
    onAfterAccountUpdate,
    openInvestmentProfileUpdateDrawer,
    openInvestmentAccreditationUpdateModal,
  })

  return (
    <>
      <Descriptions
        bordered
        size="small"
        column={1}
        className="compact black"
        labelStyle={{ width: "30%" }}
      >
        {
          items.map(({ label, value }, key) =>
            <Item
              key={key}
              label={label}
            >
              {value}
            </Item>
          )
        }
      </Descriptions>

      <InvestorDrawer
        drawer={accountDrawer}
        onAfterUpdate={onAfterAccountUpdate}
      />
    </>
  )
}

InvestmentDescriptions.propTypes = {
  account: ReadAccountOutputShape.isRequired,
  investment: InvestmentShape.isRequired,
  readInvestment: PropTypes.func.isRequired,
  openInvestmentProfileUpdateDrawer: PropTypes.func.isRequired,
  openInvestmentAccreditationUpdateModal: PropTypes.func.isRequired,
}

export default InvestmentDescriptions
