import React from "react"
import PropTypes from "prop-types"

import ListInput from "./ListInput"
import FormShape from "../shapes/FormShape"
import { TYPE_HIDDEN, TYPE_NONE } from "../FormItem"

const ASSET_TYPE = "EMBEDED_VIDEO"

const LABEL_TITLE = "Title"
const LABEL_URL = "URL"


const LinksListInput = ({
  urlPlaceholder = LABEL_URL,
  namePlaceholder = LABEL_TITLE,
  linkValidationRules = [],
  form,
  name,
  path,
  title,
  addTitle,
}) => {
  const schema = [
    {
      name: "name",
      placeholder: namePlaceholder,
      inputProps: { rows: 1 },
      itemProps: { noStyle: true }
    },
    {
      name: "url",
      placeholder: urlPlaceholder,
      itemProps: {
        rules: linkValidationRules,
        noStyle: true
      }
    }, {
      name: "description",
      type: TYPE_NONE,
    }, {
      type: TYPE_HIDDEN,
      name: "type",
      initialValue: ASSET_TYPE
    }, {
      type: TYPE_HIDDEN,
      name: "isPublic",
      initialValue: true,
    }, {
      type: TYPE_HIDDEN,
      name: "path",
      initialValue: "NA",
    }
  ]

  return (
    <ListInput
      form={form}
      name={name}
      path={path}
      title={title}
      schema={schema}
      addTitle={addTitle}
      className="horizontal-oriented"
    />
  )
}

LinksListInput.propTypes = {
  form: FormShape.isRequired,
  name: PropTypes.arrayOf(PropTypes.string).isRequired,
  path: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string.isRequired,
  addTitle: PropTypes.string.isRequired,
  urlPlaceholder: PropTypes.string,
  namePlaceholder: PropTypes.string,
  linkValidationRules: PropTypes.arrayOf(PropTypes.shape()),
}

export default LinksListInput
