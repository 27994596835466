import React, { useRef } from "react"
import PropTypes from "prop-types"
import { EditOutlined } from "@ant-design/icons"
import { Collapse, Button, Space } from "antd"

import Note from "@components/Note"
import { hasAccess } from "@components/Authorization"
import { W1, W3 } from "@components"
import { TemplatesStoreShape } from "@components/Templates"
import { ReadAccountOutputShape, updateInvestmentNoteOperation } from "@api/services/investments"

import Footer from "./Footer"
import InvestmentSteps from "./InvestmentSteps"
import InvestmentFunding from "./InvestmentFunding"
import { InvestmentShape } from "../../shapes"
import InvestmentDescriptions from "./InvestmentDescriptions"
import InvestmentProfileUpdateDrawer from "./InvestmentProfileUpdateDrawer"
import InvestmentAccreditationUpdateModal from "./InvestmentAccreditationUpdateModal"

const LABEL_NOTE = "Note"
const LABEL_EDIT = "Edit"
const LABEL_FUNDING = "Funding"
const LABEL_INVESTMENT = "Investment"


const Investment = ({
  account,
  investment,
  readInvestment,
  updateInvestment,
  projectTemplatesStore
}) => {
  const investmentProfileUpdateDrawer = useRef({})
  const investmentAccreditationUpdateModal = useRef({})

  const isNoteEditable = hasAccess(['investments-note-write'])
  const canUpdateInvestment = hasAccess(['investments-write'])

  const {
    isInvestmentFundedTemplateEnabled,
    isInvestmentSignedTemplateEnabled
  } = projectTemplatesStore

  const renderLabel = value =>
    <span style={{ fontWeight: 600 }}>{value}</span>

  const openInvestmentProfileUpdateDrawer = () =>
    investmentProfileUpdateDrawer.current.open()

  const openInvestmentAccreditationUpdateModal = () => {
    const item = {
      id: investment.id,
      hasAccreditation: investment.investor?.hasAccreditation,
      accreditationExpiresAt: investment.investor?.accreditationExpiresAt,
    }

    investmentAccreditationUpdateModal.current.openItem(item)
  }

  const onClick = e => {
    e.stopPropagation()
    openInvestmentProfileUpdateDrawer()
  }

  const onAfterNoteSubmit = investmentNoteAttributes =>
    updateInvestment({ ...investment, ...investmentNoteAttributes })

  const getExtra = () => !canUpdateInvestment
    ? null
    : (
      <Button
        key="edit"
        icon={<EditOutlined />}
        size="small"
        shape="round"
        onClick={onClick}
      >
        {LABEL_EDIT}
      </Button>
    )

  const items = [
    {
      key: 'descriptions',
      label: renderLabel(LABEL_INVESTMENT),
      extra: getExtra(),
      children: (
        <InvestmentDescriptions
          account={account}
          investment={investment}
          readInvestment={readInvestment}
          openInvestmentProfileUpdateDrawer={openInvestmentProfileUpdateDrawer}
          openInvestmentAccreditationUpdateModal={openInvestmentAccreditationUpdateModal}
        />
      ),
      collapsible: "icon",
    },
    {
      key: "funding",
      label: renderLabel(LABEL_FUNDING),
      children: (
        <InvestmentFunding
          investment={investment}
          readInvestment={readInvestment}
          isInvestmentFundedTemplateEnabled={isInvestmentFundedTemplateEnabled}
        />
      ),
      collapsible: "icon",
    },
    {
      key: "note",
      label: renderLabel(LABEL_NOTE),
      children: (
        <Note
          id={investment.id}
          note={investment.note}
          updatedAt={investment.noteUpdatedAt}
          updatedBy={investment.noteUpdatedBy}
          operation={updateInvestmentNoteOperation}
          isEditable={isNoteEditable}
          onAfterSubmit={onAfterNoteSubmit}
        />
      ),
      collapsible: "icon",
    },
  ]

  const style = {
    marginBottom: W3
  }

  const defaultActiveKey = [
    'descriptions',
  ]

  const collapseProps = {
    size: "small",
    style: { background: "transparent" },
    bordered: false,
    items,
    defaultActiveKey,
  }

  return (
    <Space
      size={W1}
      style={style}
      direction="vertical"
    >
      <InvestmentSteps
        investment={investment}
        readInvestment={readInvestment}
        isInvestmentSignedTemplateEnabled={isInvestmentSignedTemplateEnabled}
      />

      <div className="descriptions">
        <Collapse {...collapseProps} />
      </div>

      <Footer
        investment={investment}
        updateInvestment={updateInvestment}
      />

      <InvestmentProfileUpdateDrawer
        drawer={investmentProfileUpdateDrawer}
        account={account}
        investment={investment}
        updateInvestment={updateInvestment}
      />

      <InvestmentAccreditationUpdateModal
        modal={investmentAccreditationUpdateModal}
        onSuccess={updateInvestment}
      />
    </Space>
  )
}

Investment.propTypes = {
  account: ReadAccountOutputShape.isRequired,
  investment: InvestmentShape.isRequired,
  readInvestment: PropTypes.func.isRequired,
  updateInvestment: PropTypes.func.isRequired,
  projectTemplatesStore: TemplatesStoreShape.isRequired
}

export default Investment
