import { getConfig } from "@components/Config"

const PLATFORM_EMAIL = getConfig('PLATFORM_EMAIL')

const LABEL_TITLE = "Previously uploaded documents failed verification due to"
const LABEL_REUPLOAD = "Please resolve the issues and re-upload."
const LABEL_DOCUMENT_PHOTO_REQUIREMENTS = `
In order to reduce the chances of a document being rejected:

* All 4 Edges of the document should be visible
* A dark or high contrast background should be used
* At least 90% of the image should be the document
* Should be at least 300dpi
* Capture image from directly above the document
* Make sure that the image is properly aligned, not rotated, tilted or skewed
* No flash to reduce glare
* No black and white documents
`

export const LABEL_SUPPORTED_DOCUMENTS_INDIVIDUAL = `Supported Documents:

* Non-expired State Issued Driver’s License / Identification Card
* Non-expired US Passport
* Federal Employment Authorization Card
* US Visa

Not Supported Documents:

* Military IDs
* Expired government-issued IDs`

export const LABEL_SUPPORTED_DOCUMENTS_BUSINESS_EXTRA = `To complete verification, please upload one of the following documents:

* Filed and stamped Articles of Organization or Incorporation
* Certificate of Good Standing
* Sales/Use Tax License
* Business License

If you have any questions, please reach out to [PLATFORM_EMAIL] for clarifications.`

const OTHER_BUSINESS_DOCUMENTS = `Other business documents that are applicable
include any US government entity (federal, state, local) issued business
formation or licensing exhibiting the name of the business, or any business
formation documents exhibiting the name of the business entity in addition to
being filed and stamped by a US government entity. Examples include:

* Filed and stamped Articles of Organization or Incorporation
* Sales/Use Tax License
* Business License
* Certificate of Good Standing
`

export const LABEL_SUPPORTED_DOCUMENTS_SOLO = `Documents that are used to help
identify a business can include the following:

One or more of the following, as applicable to your trust:

* EIN documentation (IRS-issued SS4 confirmation letter)
* Fictitious Business Name Statement
* Certificate of Assumed Name
* Business License
* Sales/Use Tax License
* Registration of Trade Name
* Color copy of a valid government-issued photo ID (e.g., passport or driver’s license)

${OTHER_BUSINESS_DOCUMENTS}`

export const LABEL_SUPPORTED_DOCUMENTS_BUSINESS = `Documents that are used to
help identify a business can include the following:

* EIN Letter (IRS-issued SS4 confirmation letter)

${OTHER_BUSINESS_DOCUMENTS}`

const SUPPORTED_DOCUMENTS_MAP = {
  SOLO: LABEL_SUPPORTED_DOCUMENTS_SOLO,
  BUSINESS: LABEL_SUPPORTED_DOCUMENTS_BUSINESS,
  INDIVIDUAL: LABEL_SUPPORTED_DOCUMENTS_INDIVIDUAL,
  EXTRA_BUSINESS: LABEL_SUPPORTED_DOCUMENTS_BUSINESS_EXTRA,
}


const getFailedReasons = (failedReasons, supportedDocumentsKey) => {
  const reasons = failedReasons
    .map(value => `* ${value}`)
    .join('\n')

  const supportedDocuments = SUPPORTED_DOCUMENTS_MAP[supportedDocumentsKey] || ""
  const supportedDocumentsFormatted = supportedDocuments.replace("[PLATFORM_EMAIL]", `[${PLATFORM_EMAIL}](mailto:${PLATFORM_EMAIL})`)

  const markdown = [
    `${LABEL_TITLE}:\n`,
    `${reasons}\n\n`,
    `${LABEL_DOCUMENT_PHOTO_REQUIREMENTS}\n\n`,
    `${supportedDocumentsFormatted}\n\n`,
    `${LABEL_REUPLOAD}`
  ].join('')

  return markdown
}

export default getFailedReasons
