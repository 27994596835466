import { useOutletContext } from "react-router-dom"
import dayjs from "dayjs"
import keyBy from "lodash.keyby"
import chunk from "lodash.chunk"
import sortBy from "lodash.sortby"

import { FUNDING } from "@api/services/transactions/shapes/TransactionTypeEnum"
import { getConfig } from "@components/Config"
import computeStatus from "@modules/backstage/transactions/TransactionsTable/helpers/computeStatus"
import computeInvestor from "@modules/backstage/investments/InvestmentsTable/helpers/computeInvestor"
import operationRequest from "@components/Authorization/request/operationRequest"
import { getCurrencyValue } from "@components/Amount"
import { useExportProgress } from "@components/Export"
import { getUsDateFromIsoDate } from "@components/Date"
import { getTransactionTypeLabel } from "@components/Transaction"
import { getIndexOperationParameters } from "@modules/backstage/transactions/TransactionsTable/helpers/useIndexOperationParameters"
import { indexProjectInvestmentsOperation } from "@api/services/investments"
import { indexTransactionsOperation, indexFailedTransactionsOperation } from "@api/services/transactions"
import { PROFILE_TYPES_LABELS } from "@components/Domain"

import computeStatusReason from "./computeStatusReason"
import getOrganizationProjects from "./getOrganizationProjects"

const LABEL_DATE = "Date"
const LABEL_AMOUNT = "Amount"
const LABEL_REASON = "Status Reason"
const LABEL_STATUS = "Status"
const LABEL_CHANNEL = "Channel"
const LABEL_PROFILE = "Investor Profile"
const LABEL_PROJECT_ID = "Project ID"
const LABEL_PROFILE_TYPE = "Profile Type"
const LABEL_PROJECT_NAME = "Project Name"
const LABEL_INVESTMENT_ID = "Investment ID"
const LABEL_INVESTOR_EMAIL = "Investor Email"
const LABEL_ORGANIZATION_ID = "Organization ID"
const LABEL_TRANSACTION_TYPE = "Transaction Type"
const LABEL_ORGANIZATION_NAME = "Organization Name"
// const LABEL_SENDER_DWOLLA_BALANCE = "Sender Dwolla Balance"
// const LABEL_RECEIVER_DWOLLA_BALANCE = "Receiver Dwolla Balance"

const CONCURRENT_CHUNK_SIZE = 3

const consoleOrganizationId = getConfig("consoleOrganizationId")


const concurrently = (items, callback) =>
  Promise.all(items.map(item => callback(item)))


const useDistributionsReport = (onReady, isFailedDistributionsOnly = false) => {
  const { organizations } = useOutletContext()

  const targetOrganizations = sortBy(
    organizations
      .filter(({ id }) => id !== consoleOrganizationId)
      .filter(({ isDisabled }) => isDisabled !== true)
  , 'name')

  const totalCount = targetOrganizations.length

  const getOragnizationTransactions = async (authorization, date) => {
    const endDate = dayjs(date).endOf('month')
    const startDate = dayjs(date).startOf('month')

    const parameters = getIndexOperationParameters({
      dateRange: [startDate, endDate]
    })

    const { data: transactions } = await operationRequest({
      operation: indexTransactionsOperation,
      headers: { authorization },
      parameters,
    })

    return transactions
  }

  const getOragnizationFailedTransactions = async (authorization) => {
    const { data: transactions } = await operationRequest({
      operation: indexFailedTransactionsOperation,
      headers: { authorization },
    })

    return transactions
  }

  const getOrganizationInvestmentsMap = async (authorization, projectId) => {
    const parameters = {
      projectId,
    }

    const { data: investments } = await operationRequest({
      operation: indexProjectInvestmentsOperation,
      headers: { authorization },
      parameters
    })

    return keyBy(investments, "id")
  }

  const getProjectDistributions = (targetProjectId, transactions) =>
    transactions
      .filter(({ type }) => type !== FUNDING)
      .filter(({ projectId }) => projectId === targetProjectId)
      .filter(({ retryTransactionId }) => !retryTransactionId)

  const computeRow = (organization, project, investmentMap, distribution) => {
    const {
      id: organizationId,
      name: organizationName,
    } = organization

    const {
      id: projectId,
      name: projectName,
    } = project

    const {
      type,
      date,
      amount,
      status,
      isExternal,
      investmentId,
      statusReason,
      investmentName,
    } = distribution

    const investment = investmentMap[investmentId]

    const profile = investment
      ? computeInvestor(investment)
      : investmentName

    let email = ""
    let profileType = ""

    if (investment) {
      email = investment.investor.email
      profileType = investment.profileType
    }

    const channel = isExternal
      ? "Backfill"
      : "Dwolla"

    const globalInvestmentId = `${organizationId}-${projectId}-${investmentId}`

    const row = {
      [LABEL_ORGANIZATION_ID]: organizationId,
      [LABEL_ORGANIZATION_NAME]: organizationName,
      [LABEL_PROJECT_ID]: projectId,
      [LABEL_PROJECT_NAME]: `${projectName}`.trim(),
      [LABEL_INVESTOR_EMAIL]: email,
      [LABEL_INVESTMENT_ID]: globalInvestmentId,
      [LABEL_PROFILE]: profile,
      [LABEL_PROFILE_TYPE]: PROFILE_TYPES_LABELS[profileType],
      [LABEL_TRANSACTION_TYPE]: getTransactionTypeLabel(type),
      [LABEL_DATE]: getUsDateFromIsoDate(date),
      [LABEL_AMOUNT]: getCurrencyValue(amount),
      [LABEL_CHANNEL]: channel,
      [LABEL_STATUS]: computeStatus(distribution),
      [LABEL_REASON]: computeStatusReason(status, statusReason)
    }

    return row
  }

  const getRows = async ({ authorizeOrganization, shouldStop, updateProgress }, isoDate) => {
    const rows = []

    let index = 1

    for (const organization of targetOrganizations) {
      if (shouldStop()) {
        return
      }

      const {
        id: organizationId,
        name: organizationName
      } = organization

      // eslint-disable-next-line no-console
      console.info('Export organization', `${organizationId} - ${organizationName}`)

      const authorization = await authorizeOrganization(organizationId)

      const transactions = await (
        isFailedDistributionsOnly
          ? getOragnizationFailedTransactions(authorization)
          : getOragnizationTransactions(authorization, isoDate)
      )

      const hasTransactions = transactions.length > 0

      if (hasTransactions) {
        const projects = await getOrganizationProjects(authorization)

        const chunks = chunk(projects, CONCURRENT_CHUNK_SIZE)

        for (const chunkItem of chunks) {
          if (shouldStop()) {
            break
          }

          await concurrently(chunkItem, async project => {
            if (shouldStop()) {
              return
            }

            const { id: projectId } = project

            const investmentMap = await getOrganizationInvestmentsMap(authorization, projectId)
            const projectDistributions = getProjectDistributions(projectId, transactions)

            for (const distribution of projectDistributions) {
              if (shouldStop()) {
                break
              }

              const row = computeRow(organization, project, investmentMap, distribution)
              rows.push(row)
            }
          })
        }
      }

      updateProgress(index, totalCount)
      index++
    }

    return rows
  }

  const fileName = isFailedDistributionsOnly
    ? "failed_distributions_report"
    : "distributions_report"

  return useExportProgress({ onReady, fileName, getRows })
}

export default useDistributionsReport
