import { ROLES } from "@components/Domain"

const getSelectRolesState = (rolesInputValue) => {
  const hasAdministratorRole = rolesInputValue.includes(ROLES.ADMIN)
  const hasAccountantRole = rolesInputValue.includes(ROLES.ACCOUNTANT)
  const hasApproverRole = rolesInputValue.includes(ROLES.APPROVER)

  if (hasAdministratorRole) {
    return [ true, false, false ]
  }

  if (hasAccountantRole) {
    return [ false, true, false ]
  }

  if (hasApproverRole) {
    return [ false, false, true ]
  }

  return [ false, false, false ]
}

const getHiddenAdditionalPermissionInputNames = (rolesInputValue) => {
  const [
    isAdministratorSelected,
    isAccountantSelected,
    isApproverSelected,
  ] = getSelectRolesState(rolesInputValue)

  let HIDE_INPUTS = []

  if (isAccountantSelected) {
    HIDE_INPUTS = [ "_isAccountant" ]
  }

  if (isApproverSelected) {
    HIDE_INPUTS = [ "_isAccountant", "_isApprover" ]
  }

  const isOtherSelected =
    !isAdministratorSelected &&
    !isAccountantSelected &&
    !isApproverSelected

  if (isOtherSelected) {
    HIDE_INPUTS = [ "_isAccountant", "_isApprover" ]
  }

  return HIDE_INPUTS
}

export default getHiddenAdditionalPermissionInputNames
