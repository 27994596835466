import { useState, useEffect, useCallback } from "react"
import { useOutletContext } from "react-router-dom"

import { ROLES } from "@components/Domain"
import { useAppContext } from "@components/AppContext"
import { updateMemberOperation } from "@api/services/backstage"

import getInputs from "./getInputs"
import getHiddenAdditionalPermissionInputNames from "../../UserCreateModal/helpers/getHiddenAdditionalPermissionInputNames"


const useSchema = (form, user) => {
  const [ schema, setSchema ] = useState([])

  const { organization, userGroups } = useOutletContext()
  const { isConsoleOrganization } = useAppContext()

  const {
    id: userId,
    roles
  } = user

  const {
    name: organizationName,
    controllerId,
  } = organization

  const isLiaison = (roles || []).includes(ROLES.BROKER)
  const isApprover = (roles || []).includes(ROLES.APPROVER)
  const isAccountant = (roles || []).includes(ROLES.ACCOUNTANT)

  const isController = userId === controllerId

  const updateSchema = useCallback(() => {
    const { roles: formRoles = [] } = form.getFieldsValue()

    const HIDE_INPUTS = getHiddenAdditionalPermissionInputNames(formRoles)

    const isManager = formRoles.includes(ROLES.MANAGER)

    const inputs = getInputs({
      isLiaison,
      isManager,
      userGroups,
      isController,
      updateSchema,
      organizationName,
      isConsoleOrganization
    })
      .filter(input => !HIDE_INPUTS.includes(input.name))

    const newSchema = updateMemberOperation.getSchema(inputs)
    setSchema(newSchema)
  }, [
    form,
    isLiaison,
    userGroups,
    isController,
    organizationName,
    isConsoleOrganization,
  ])

  useEffect(() => {
    updateSchema()
  }, [ updateSchema ])

  const setAdditionalPermissions = useCallback(() => {
    form.setFieldValue("_isLiaison", isLiaison)
    form.setFieldValue("_isApprover", isApprover)
    form.setFieldValue("_isAccountant", isAccountant)
  }, [
    form,
    isLiaison,
    isApprover,
    isAccountant,
  ])

  useEffect(() => {
    setAdditionalPermissions()
  }, [ setAdditionalPermissions ])

  return schema
}

export default useSchema
export { updateMemberOperation }
