import { useOutletContext } from "react-router-dom"
import chunk from "lodash.chunk"
import keyBy from "lodash.keyby"

import { getConfig } from "@components/Config"
import { getCurrencyValue } from "@components/Amount"
import { STATE_LABELS_MAP } from "@components/Domain"
import { useExportProgress } from "@components/Export"
import { getUsDateFromIsoDate } from "@components/Date"

import getTransactionReport from "./getTransactionReport"
import getProjectInvestments from "../../InvestmentsReportModal/helpers/getProjectInvestments"
import getOrganizationProjects from "../../DistributionsReportModal/helpers/getOrganizationProjects"

const LABEL_PROJECT_ID = "Project ID"
const LABEL_PROJECT_NAME = "Project Name"
const LABEL_EQUITY_RAISED = "Total Equity Raised"
const LABEL_PROJECT_STATE = "Project State"
const LABEL_EQUITY_RECEIVED = "Total Equity Received"
const LABEL_ORGANIZATION_ID = "Organization ID"
const LABEL_ORGANIZATION_NAME = "Organization Name"
const LABEL_TOTAL_DISTRIBUTIONS = "Total Distributions"
const LABEL_PROJECT_GO_LIVE_DATE = "Go Live Date"

const consoleOrganizationId = getConfig("consoleOrganizationId")

const CONCURRENT_CHUNK_SIZE = 3


const concurrently = (items, callback) =>
  Promise.all(items.map(item => callback(item)))


const getTotalAmount = (investments = []) => {
  const totalAmount = investments
    .map((investment) => investment.amount)
    .reduce((_, amount) => _ + amount, 0)

  return totalAmount
}


const getEquityRaisedAmount = async (authorization, projectId) => {
  const activeProjectInvestments = await getProjectInvestments(authorization, projectId)

  const amount = activeProjectInvestments
    .map((investment) => getTotalAmount(investment.investments))
    .reduce((_, amnt) => _ + amnt, 0)

  return amount
}


const useProjectsReport = (onReady) => {
  const fileName = "projects_report"

  const { organizations } = useOutletContext()

  const targetOrganizations = organizations
    .filter(({ id }) => id !== consoleOrganizationId)
    .filter(({ isDisabled }) => isDisabled !== true)

  const totalCount = targetOrganizations.length

  const computeRow = (organization, project, projectTransactionReport, equityRaisedAmount) => {
    const {
      id: organizationId,
      name: organizationName,
    } = organization

    const {
      id: projectId,
      name: projectName,
      state,
      openDate,
    } = project

    const {
      equityReceivedAmount,
      distributionsAmount
    } = projectTransactionReport

    const equityRaisedCurrencyAmount = getCurrencyValue(equityRaisedAmount)
    const distributionsCurrencyAmount = getCurrencyValue(distributionsAmount)
    const equityReceivedCurrencyAmount = getCurrencyValue(equityReceivedAmount)

    const globalProjectId = `${organizationId}-${projectId}`
    const stateLabel = STATE_LABELS_MAP[state]

    return {
      [LABEL_ORGANIZATION_ID]: organizationId,
      [LABEL_ORGANIZATION_NAME]: organizationName,
      [LABEL_PROJECT_ID]: globalProjectId,
      [LABEL_PROJECT_NAME]: `${projectName}`.trim(),
      [LABEL_PROJECT_GO_LIVE_DATE]: getUsDateFromIsoDate(openDate),
      [LABEL_PROJECT_STATE]: stateLabel,
      [LABEL_EQUITY_RAISED]: equityRaisedCurrencyAmount,
      [LABEL_EQUITY_RECEIVED]: equityReceivedCurrencyAmount,
      [LABEL_TOTAL_DISTRIBUTIONS]: distributionsCurrencyAmount,
    }
  }

  const getRows = async ({ authorizeOrganization, shouldStop, updateProgress }) => {
    const rows = []

    let index = 1

    for (const organization of targetOrganizations) {
      if (shouldStop()) {
        return
      }

      const {
        id: organizationId,
        name: organizationName,
      } = organization

      // eslint-disable-next-line no-console
      console.info('Export organization', `${organizationId} - ${organizationName}`)

      const authorization = await authorizeOrganization(organizationId)

      const [
        transactionReport,
        projects,
      ] = await Promise.all([
        getTransactionReport(authorization),
        getOrganizationProjects(authorization)
      ])

      const { projectTransactionReports } = transactionReport
      const projectTransactionReportsMap = keyBy(projectTransactionReports, "projectId")

      const chunks = chunk(projects, CONCURRENT_CHUNK_SIZE)

      for (const chunkItem of chunks) {
        if (shouldStop()) {
          break
        }

        await concurrently(chunkItem, async project => {
          if (shouldStop()) {
            return
          }

          const { id: projectId } = project
          const projectTransactionReport = projectTransactionReportsMap[projectId] || {
            equityReceivedAmount: 0,
            distributionsAmount: 0,
          }

          // NOTE: This can be replaced with project.amount after number is fixed
          //       for investments that has been migrated from one org to another.
          const equityRaisedAmount = await getEquityRaisedAmount(authorization, projectId)

          const row = computeRow(organization, project, projectTransactionReport, equityRaisedAmount)
          rows.push(row)
        })
      }

      updateProgress(index, totalCount)
      index++
    }

    return rows
  }

  return useExportProgress({ onReady, fileName, getRows })
}

export default useProjectsReport
