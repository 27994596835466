import React from "react"
import { Link, useParams, useOutletContext } from "react-router-dom"
import { Typography } from "antd"

import { Value } from "@components/Text"
import { TRANSACTION_STATUS } from "@components/Domain"
import { getTransactionTypeLabel, getTransactionStatusLabel } from "@components/Transaction"

import InvestorTransactionStatusTag from "../InvestorTransactionStatusTag"


const useTableColumns = () => {
  const { projectId } = useParams()
  const { investmentsMap } = useOutletContext()

  const getProjectName = investmentId =>
    investmentsMap[investmentId]?.projectName || investmentId

  const getInvestmentName = investmentId =>
    investmentsMap[investmentId]?.name || investmentId

  const investmentColumnProps = {
    key: "investmentId",
    title: "Investment",
    isFilterEnabled: true,
    compute: ({ investmentId }) =>
      getProjectName(investmentId),
    render: (name, transaction) =>
      <Typography.Text strong>
        <Link to={`/projects/${transaction.projectId}/about`}>
          {name}
        </Link>
      </Typography.Text>,
  }

  const columns = []

  if (!projectId) {
    columns.push({ ...investmentColumnProps })
  }

  const profileColumnProps = {
    key: "investorAccountId",
    title: "Profile",
    isFilterEnabled: true,
    compute: ({ investmentId }) =>
      getInvestmentName(investmentId),
  }

  const typeColumnProps = {
    key: "type",
    compute: ({ type }) => getTransactionTypeLabel(type) || type,
    isFilterEnabled: true,
  }

  const dateProps = {
    key: "date",
    title: "Date",
    render: value =>
      <Value value={value} />,
  }

  const amountProps = {
    key: "amount",
    render: value =>
      <Value value={value} />,
  }

  const statusColumnProps = {
    key: "status",
    compute: ({ status }) => getTransactionStatusLabel(status),
    isFilterEnabled: true,
    filterValues: [
      TRANSACTION_STATUS.ERROR,
      TRANSACTION_STATUS.FAILED,
      TRANSACTION_STATUS.PENDING,
      TRANSACTION_STATUS.PROCESSED
    ],
    render: (value, { status }) =>
      <InvestorTransactionStatusTag status={status} value={value} />,
  }

  columns.push(
    profileColumnProps,
    typeColumnProps,
    dateProps,
    amountProps,
    statusColumnProps
  )

  return columns
}

export default useTableColumns
