import React from "react"
import { Typography } from "antd"

import { W0 } from "@components"
import { TYPE_CHECKBOX } from "@components/Form"

const LABEL_LIAISON = "Staff is a liaison of"
const LABEL_APPROVER = "Staff is a transaction approver of"
const LABEL_ACCOUNTANT = "Staff is an accountant of"


const getAdditionalPermissionInputs = (
  organizationName,
  isBrokerCheckboxDisabled = false,
  isAccountantCheckboxDisabled = false,
  isApproverCheckboxDisabled = false,
) => {
  return [
    {
      name: "_additionalPermissions",
      component: () => (
        <div style={{ marginBottom: W0 / 2 }}>
          <Typography.Text strong>
            Additional Permissions
          </Typography.Text>
        </div>
      )
    },
    {
      name: "_isApprover",
      type: TYPE_CHECKBOX,
      label: '',
      required: false,
      itemProps: {
        style: { marginBottom: 0 },
      },
      inputProps: {
        label: `${LABEL_APPROVER} ${organizationName}`,
        disabled: isApproverCheckboxDisabled,
      }
    },
    {
      name: "_isAccountant",
      type: TYPE_CHECKBOX,
      label: '',
      required: false,
      itemProps: {
        style: { marginBottom: 0 },
      },
      inputProps: {
        label: `${LABEL_ACCOUNTANT} ${organizationName}`,
        disabled: isAccountantCheckboxDisabled,
      }
    },
    {
      name: "_isLiaison",
      type: TYPE_CHECKBOX,
      label: '',
      required: false,
      inputProps: {
        label: `${LABEL_LIAISON} ${organizationName}`,
        disabled: isBrokerCheckboxDisabled,
      }
    },
  ]
}

export default getAdditionalPermissionInputs
