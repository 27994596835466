import React from "react"
import { Tag } from "antd"
import PropTypes from "prop-types"

import { properties } from "@api/services/transactions/IndexTransactionsOutputShape"

import {
  ERROR,
  FAILED,
  PENDING,
  REJECTED,
  PROCESSED,
  CANCELLED,
} from "@api/services/transactions/shapes/TransactionStatusEnum"

const RETRIED = "RETRIED"
const PENDING_APPROVAL = "PENDING_APPROVAL"

const COLORS_MAP = {
  [ERROR]: "red",
  [FAILED]: "red",
  [RETRIED]: null,
  [PENDING]: "blue",
  [REJECTED]: "red",
  [CANCELLED]: null,
  [PROCESSED]: "green",
  [PENDING_APPROVAL]: "orange",
}

const LABELS_MAP = {
  [ERROR]: "Error",
  [FAILED]: "Failed",
  [RETRIED]: "Retried",
  [PENDING]: "Processing",
  [REJECTED]: "Rejected",
  [PROCESSED]: "Processed",
  [CANCELLED]: "Cancelled",
  [PENDING_APPROVAL]: "Pending Approval",
}

const getTransactionStatusLabel = status => LABELS_MAP[status] || status

const getTransactionStatusColor = status => COLORS_MAP[status]

const TransactionStatusTag = ({ transaction }) => {
  let { status } = transaction
  const { retryTransactionId, isApproved } = transaction

  const isPending = status === PENDING
  const isPendingApproval = isPending && !isApproved

  if (isPendingApproval) {
    status = PENDING_APPROVAL
  }

  const isRetried = !!retryTransactionId

  if (isRetried) {
    status = RETRIED
  }

  const label = getTransactionStatusLabel(status)
  const color = getTransactionStatusColor(status)

  return (
    <Tag
      color={color}
      bordered={false}
    >
      {label}
    </Tag>
  )
}

TransactionStatusTag.propTypes = {
  transaction: PropTypes.shape({
    status: properties.status,
    isApproved: PropTypes.bool,
    retryTransactionId: properties.retryTransactionId,
  }).isRequired,
}

export default TransactionStatusTag

export {
  getTransactionStatusLabel,
  getTransactionStatusColor
}
